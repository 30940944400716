import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC } from 'react';
import { AddressProps } from 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/model';

const Address: FC<AddressProps> = ({
  address: [
    {
      properties: { title, data },
    },
  ],
}) => (
  <div className="gs-footer-top__address">
    <p className="gs-footer-top__title">{title}</p>
    <div className="gs-footer-top__address-data">
      <DangerouslySetInnerHtml html={data} />
    </div>
  </div>
);

export default Address;
