import React, { FC } from 'react';
import { SupportProps } from 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/model';

const Support: FC<SupportProps> = ({
  support: [
    {
      properties: { title, phone },
    },
  ],
}) => (
  <div className="gs-footer-top__support">
    <p className="gs-footer-top__title">{title}</p>
    {phone
      ? phone.map(item => (
          <div className="gs-footer-top__phone" key={item}>
            <a href={`tel:${item.replace(/\s+/g, '')}`}>{item}</a>
          </div>
        ))
      : null}
  </div>
);

export default Support;
