const additionalSeoProps = {
  script: [
    {
      type: `application/ld+json`,
      innerHTML: `
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Gavisconell - Médicament estomac ",
        "description": "Ne laissez pas les maux d'estomac gâcher votre journée. Gavisconell est là pour vous aider à soulager les brûlures d’estomac et les remontées acides.",
        "logo": "https://www.gavisconell.fr/media/2197/gaviscon-com-fr-a5ca556c-14cb-4d34-8c1b-f6954627b8af.jpg",
        "telephone": "",
        "email": "",
        "url": "https://www.gavisconell.fr/",
        "sameAs": ["", "", ""],
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "",
          "postalCode": "",
          "streetAddress": ""
        }
      }
    `,
    },
  ],
};

export default additionalSeoProps;