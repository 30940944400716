import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import { parseBoolean } from 'gatsby-theme-gaviscon/src/utils/parseHelpers';
import LanguageSelector from 'gatsby-theme-gaviscon/src/components/common/LanguageSelector';
import Support from './components/Support';
import Address from './components/Address';

import 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/TopFooter.scss';
import { ITopFooter } from 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/model';

const TopFooter: FC<ITopFooter> = ({ logo, navigation, address, support, lsSettings, middleCopyright }) => (
  <div className="gs-footer-top">
    <Container fluid>
      {middleCopyright ? (<DangerouslySetInnerHtml
        className="gs-footer-top__copyright"
        html={middleCopyright}
      />) : null}
       {logo?.length ? (
        <GatsbyImage
          className="gs-footer-top__logo"
          fluid={logo[0].properties.logo}
          alt={logo[0].properties.altText}
        />
      ) : null}
      <Row>
        {support?.length && address?.length ? (
          <Col lg="4">
            {parseBoolean(lsSettings.inFooter) ? (
              <LanguageSelector type="list" {...lsSettings} />
            ) : null}
            {support.length ? <Support {...{ support }} /> : null}
            {address.length ? <Address {...{ address }} /> : null}
          </Col>
        ) : null}
        <Col lg="8" className="gs-footer-top__nav">
          {navigation?.length > 0 &&
            navigation.map(({ properties: { title, links } }) => (
              <div className="gs-footer-top__nav-group" key={title}>
                <p className="gs-footer-top__title">{title}</p>
                <ul className="gs-footer-top__nav-list">
                  {links.map(({ properties: { link: [{ name, url }] } }) => (
                    <li key={name}>
                      <Link to={url}>
                        <span className="gs-footer-top__nav-list-label">{name}</span>
                        <IconCustom icon="chevron-right" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </Col>
      </Row>
    </Container>
  </div>
);

export default TopFooter;
